<template>
<div @keydown.ctrl.s="saveShortcut" class="">
	<div :ref="getID('monaco-editor')" :id="getID('monaco-editor')" class="editor" :style="`height: ${height}; max-height: ${height};`" />
</div>
</template>

<!-- 
  ADVICE: if using this in any situation where Monaco.vue may be shown or hidden- like on tabs, collapses or alike - make sure to set these to 'lazy',
  this component may struggle with resizing or rendering it repeatedly
 -->
 
<script>
import loader from '@monaco-editor/loader';
import { v4 as uuidv4 } from 'uuid';
import { integer } from 'vee-validate/dist/rules';
export default {
	data(){
		return{
			uuidMap: {},
			monacoInstance: undefined,
			monacoEditor: undefined,
			internalChange: false,
			timeout: null
		}
	},
	props: {
		height: {
			type: String,
			default: "300px",
		},
		language: {
			type: String,
			required: true
		},
		minimap_on:{
			type: Boolean,
			default: false
		},
		value: {
			type: String,
			default: "",
		},
		updateInterval:{
			Type: integer, //if other than -1 any changes made in the editor will emmit editorChange instantaneously
			default: -1,//this is the minimal amount of miliseconds between emittions of editorChange
		},
		disableEditing:{
			type: Boolean,
			default: false,
		}
	},
	beforeDestroy () {
		this.$emit('destroyed');
	},
	mounted() {
    this.init()
	},
	computed: {
		vModel: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			}
		},
	},
	watch: {
		vModel(val) {
			if (!this.internalChange && this.monacoInstance ) {
				this.monacoInstance.setValue(this.vModel);
			}
		},
	},
	methods: {
    init(){
      const wrapper = this.$refs[this.getID('monaco-editor')];
      loader.init().then(monaco => {
        this.$emit('loaded');
        this.monacoEditor = monaco.editor.create(wrapper, {
          value: this.value,
          language: this.language,
          theme: 'vs-dark',
          readOnly: this.disableEditing,
          minimap: {enabled: this.minimap_on},
        })
        
        this.monacoInstance = this.monacoEditor.getModel();
        
        this.monacoInstance.onDidChangeContent( (event) => {
          this.internalChange = true;
          this.vModel = this.monacoInstance.getValue();

          this.$nextTick(function () {
            this.internalChange = false;
          });
          if (this.updateInterval != -1){
            this.updateTimer();
          }
        });

//quickCommand
//wordHighlight.trigger
//findWithSelection

        // this.monacoEditor.action.referenceSearch.trigger('salda');

        // console.log(JSON.stringify(Object.getOwnPropertyNames(this.monacoEditor._actions['actions.find'])))

        // console.log((this.monacoEditor._actions['editor.action.referenceSearch.trigger']._run('banana')))
        // console.log(this.monacoEditor._actions['actions.find']._run('sal',"sal"))
        

        //this one can trigger a search, but doesn't pass in any search terms
          // this.monacoEditor._actions['actions.find']._run('s')
          // this.monacoEditor._actions['editor.actions.findWithArgs']._run('s')

        // console.log(this.monacoEditor._actions['actions.find'])
        
        // this.monacoEditor._actions['editor.actions.findWithArgs'].label = 'salada'
        // this.monacoEditor._actions['editor.actions.findWithArgs'].alias = 'salada'
        // this.monacoEditor._actions['editor.actions.findWithArgs']._precondition = 'saladsa'
        // this.monacoEditor._actions['editor.actions.findWithArgs']._run()

      });
      
    },
		getID(key) {
			if (this.uuidMap[key]) {
				return this.uuidMap[key];
			}
			const uuid = uuidv4();
			this.uuidMap[key] = uuid;

			return uuid;
		},
		type(text) {
			this.monacoEditor.trigger('keyboard', 'type', { text });
		},
		updateTimer(){
      if (this.timeout){
        clearTimeout(this.timeout)
      }

      this.timeout = setTimeout(() => {
        this.$emit('editorChange');
      }, this.updateInterval);
		},
    saveShortcut(e){
      e.preventDefault();
      this.$emit('MonacoCtrlS');
    },		
	},
}
</script>

<style lang="scss" scoped >

.editor{
  background-color: #1e1e1e;
}


</style>



