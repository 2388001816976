<template>
  <b-card
    class="integrator-service-readme-card"
    header-class="readme-card-header px-0 pb-0"
  >
    <template #header>
      <p class="my-0 ml-2 title text-light">
        {{ $t('Readme') }}
      </p>
      <hr class="w-100"/>
    </template>

    <b-container fluid class="px-0">
      <b-row>
        <b-col :class="cardBodyClass" md="12">
          <b-form-group
            label-for="description-input"
            class="mb-0 form-group-description description-input-container"
          >
            <textarea id="description-input" ></textarea>
          </b-form-group>
        </b-col>
        <b-col md="12">
         <slot name="footer"></slot>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
import { 
  BCard,
  BContainer,
  BCol,
  BRow,
  BFormGroup,
} from 'bootstrap-vue'
import EasyMDE from 'easymde'
import 'easymde/dist/easymde.min.css'
  export default {
    components: {
      BCard,
      BContainer,
      BCol,
      BRow,
      BFormGroup,
    },
    props: {
      value: {
        type: undefined,
        required: true
      },
      readOnly:{
        type: Boolean,
        default: false,
      },
      cardBodyClass:{
        type: String,
        default: ''
      }
    },
    data() {
      return {
        easyMDE: null,
      }
    },
    computed: {
      readme: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      }
    },
    mounted () {
      this.init();
    },
    methods: {
      init() {
        if(this.readOnly){
          this.easyMDE = new EasyMDE({
            element: document.getElementById('description-input'),
            spellChecker: false,
            
            toolbar: false,
            
            onToggleFullScreen: (state) => this.togglePreviewFullscreen(state)
          });        
          this.easyMDE.value(this.readme || "")
          this.easyMDE.togglePreview()
          
        } else {
          this.easyMDE = new EasyMDE({
            showIcons: ['strikethrough', 'code', 'table', 'redo', 'heading', 'undo', 'heading-1', 'heading-2', 'heading-3', 'clean-block', 'horizontal-rule'],
            element: document.getElementById('description-input'),
            maxHeight: '500px',
            spellChecker: false,
            onToggleFullScreen: (state) => this.togglePreviewFullscreen(state)
          });     
          this.easyMDE.value(this.readme || "")     
        }
        

        this.easyMDE.codemirror.on("change", () => {
          this.readme = this.easyMDE.value();
          this.$emit('changed');
        });
      },
      togglePreviewFullscreen(state) {
        const navbar = document.getElementsByClassName('navbar header-navbar')[0]
        const side_menu = document.getElementsByClassName('main-menu')[0]

        if (state) {
          navbar.style.display = 'none';
          side_menu.style.display = 'none';
        } else {
          navbar.style.display = 'flex';
          side_menu.style.display = 'table-cell';
        }  
      },
    },
  }
</script>

<style lang="scss" scoped>

.integrator-service-readme-card {
  .readme-card-header {
    padding-top: 15px;
   .title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }
  }
}
</style>

<style lang="scss">


@import '@/assets/scss/variables/_variables.scss';

.form-group-description {
  .EasyMDEContainer {
    .editor-toolbar {
      background-color: #283046 !important;
      border: 1px solid #3b4253;
      & button {
        color: $fiqonLightGray;
        border: none;
        transition: all 0.1s ease;
        &:hover {
          background-color: $backgroundDark !important;
          transition: all 0.1s ease;
        }
        &.active {
          background-color: $backgroundDark !important;
          border: 1px solid #3b4253;
        }
      }
     
    }
    .CodeMirror {
      color: $fiqonLightGray;
      border: 1px solid #3b4253;
      background-color: #1f2436 !important;
      .CodeMirror-lines {
        .CodeMirror-cursor {
            border-left: 1px solid $fiqonLightGray;
            border-right: none;
        }
        .CodeMirror-code {
          color: $fiqonLightGray;
          pre {
            background-color: #1f2436 !important;
          }
        }
      }
    }
    .editor-preview-side {
      border: 1px solid #3b4253;
      background-color: #1f2436 !important;
    }
    .editor-preview-full {
      background-color: #1f2436 !important;
    }
  }
}
</style>

<style lang="scss">
.description-input-container{
  .table{
    width: unset !important;    
  }
}
</style>