<template>
  <div class="integrator-service-badge">
    <span 
      v-for="badge in badges" 
      :key="badge.label"
    >
      <b-badge 
        pill  
        :variant="badge.variant || 'light-purple'"
      >
        <feather-icon
          :icon="badge.icon || ''"
        />
        <p class="integrator-service-badge-label m-0">
          {{ badge.label }}
        </p>
      </b-badge>
     
    </span>
    <template v-if="!blockEdit">
    
      <b-button id="popover-button-default" variant="none" class="m-0 p-0" @click="editServiceCategory">
          <feather-icon
            icon="EditIcon"
            size="16"
            class="text-secondary cursor-pointer ml-50"
          />
        </b-button>
        
          <b-popover
            target="popover-button-default"
            triggers="focus"
            right
            variant="purple"
          >
          <template #title>
            <span>Edit category</span>
          </template>

          <v-select :options="allCats" v-model="selectedBadge" @input="changeBadge()" autofocus :clearable="false"></v-select>
        </b-popover>
    
    </template> 
  </div>
</template>

<script>
import { 
  BBadge,
  BDropdown,
  BPopover,
  BButton,
  BFormInput,
  BFormSelect


} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { makeToast } from '@/layouts/components/Popups'

  export default {
    components: {
      BBadge,
      BDropdown,
      BPopover,
      BButton,
      BFormInput,
      BFormSelect,
      vSelect
    },
    props: { 
      serviceID: {
        type: [ Number, String ],
        required: true,
      },
      orgID: {
        type: [ Number, String ],
        required: true,
      },
      categories:{
        type: [Object],
        required: true
      },
      blockEdit:{
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        badges: null,
        selectedBadge: null,
        showPopover: false
      }
    },
    computed: {
      ...mapGetters('serviceModule', ['getIntegratorServiceByID']),
      ...mapGetters('categoryModule',['getCategories']),
      category() {
        return this.getIntegratorServiceByID(this.orgID, this.serviceID, 'integrator_category')
      },
      allCats(){
        return this.getCategories
      },
    },
    mounted () {
      this.init();
      
    },
    methods: {
      init() {
        this.getServiceBadgesByID()
        this.fetchAllCAats()
      },
      getServiceBadgesByID() {
        this.$store.dispatch('serviceModule/fetchServiceBadges', this.serviceID).then((resp) => {
          this.badges = resp.data
          this.badges.push(this.categories)
        }).catch((error) => {
          console.log(error)
        })
      },
      fetchAllCAats(){
        this.$store.dispatch('categoryModule/getAllCategories').then(() =>{
        }).catch((err)=>{
          console.error(err)
        });
      },
      editServiceCategory() {
        this.selectedBadge = this.badges[this.badges.length - 1]
      },
      changeBadge(){        
        let payload = {
          serviceID: parseInt(this.serviceID),
          field: 'integrator_category',
          new_value: parseInt(this.selectedBadge.id)
        }
        this.$store.dispatch('serviceModule/patchIntegratorService', payload).then((resp) => {
          makeToast({
              title: 'Success!',
              text: 'Category name changed successfully',
              variant: "success",
              icon: "CheckIcon",
            })
          this.badges[this.badges.length - 1].label = this.selectedBadge.label
          this.badges[this.badges.length - 1].id = this.selectedBadge.id
          this.$emit('saved')

            
        }).catch((error) => {
          console.log(error)

          makeToast({
              title: 'Error',
              text: 'Something went wrong while changing the category',
              variant: "danger",
              icon: "XIcon",
            })

          return
        })
      


      }
      
    
    },
  }
</script>

<style lang="scss" scoped>

.integrator-service-badge {
  .integrator-service-badge-label {
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px !important;
  }
}
</style>