
<template>
  <div class="bg-dark-blue p-0 rounded mb-75">
    <b-button class="p-1 text-white d-block w-100 text-left" variant="none" @click="expanded = !expanded">
      <h5 class="d-inline">
        <b-badge :variant="getMethodInfo(operationInfo.enum_metodo).variant" class="mr-1" pill>{{getMethodInfo(operationInfo.enum_metodo).label}}</b-badge>
        <b>{{operationInfo.name}}</b> 
      </h5>
      
      <span class="text-secondary mx-1 " v-if="operationInfo.endpoint">
        <span class="text-purple mr-25">{{$t('integrator.pages.service_view.releases.operation.host')}}</span><span class="selectable_text">{{operationInfo.endpoint}}</span>
      </span>
      <span class="mx-1" v-else><i class="text-muted">{{$t('integrator.pages.service_view.releases.operation.undefined')}}</i></span>
      <div class="float-right px-50">
        <b-icon icon="chevron-down" scale="1.2" variant="secondary" v-if="!expanded"/>
        <b-icon icon="chevron-up" scale="1.2" variant="secondary" v-else/>
      </div>
    </b-button>
    <div class="px-25">
      <b-collapse :visible="expanded">
        <hr class="fainter-hr mx-25 mt-0">

        <div class="pb-25 px-1">
          
          <div v-for="dir in opDirections" :key="'division-dir-'+dir.key" class="mb-1">

            <h4 class="mx-0 px-0 mt-2 mb-25">
              <feather-icon icon="MailIcon" v-if="dir.key=='received'" class="mr-50 mb-25" size="18"/>
              <feather-icon icon="SendIcon" v-else class="mr-50 mb-25" size="18"/>
              {{dir.title}}
            </h4>
            
            <div class="custom-boxed">
              <div v-for="op in opFields" :key="`division-op-${op.key}-${dir.key}`" class="mb-1">
                
                <h5 class="d-inline">{{op.title}}</h5>
                
                <template v-if="operationInfo[op.key] && operationInfo[op.key][dir.key]">

                  <div v-for="(operationName, index) in Object.keys(operationInfo[op.key][dir.key])" :key="'operation-'+uuid()" class="mt-50">

                    <b-popover
                      triggers="focus hover"
                      variant="purple"
                      offset="110"
                      :target="`comment-button-${operationInfo[op.key][dir.key][operationName].id}-${dir.key}-${op.key}`"
                      placement="lefttop"
                      custom-class="custom-popover-body-comment"
                      >
                      {{operationInfo[op.key][dir.key][operationName].note}}
                    </b-popover>

                    <b-row class="mx-0 mb-75">
                      <b-col md="4" class="w-25 d-flex">
                        <div class="w-50"><b-badge pill variant="light-purple">{{(getDataTypeInfo(operationInfo[op.key][dir.key][operationName].data_type).label).toUpperCase()}}</b-badge></div>
                        
                        <div class="w-50 ">
                          <span v-if="operationName">{{operationName}}</span>
                          <span v-else><i class="text-muted">{{$t('integrator.pages.service_view.releases.operation.undefined')}}</i></span>
                        </div>            
                      </b-col>
                      <b-col md="4" class="w-50"><span class="text-secondary">
                        <span v-if="operationInfo[op.key][dir.key][operationName].example">{{operationInfo[op.key][dir.key][operationName].example}}</span>
                        <span v-else><i class="text-muted">{{$t('integrator.pages.service_view.releases.operation.undefined')}}</i></span>
                      </span></b-col>
                      <b-col md="4" class="text-right">
                        <b-button class="p-0 px-1" variant="none" @click="$bvModal.show('field-comment-modal')" :id="`comment-button-${operationInfo[op.key][dir.key][operationName].id}-${dir.key}-${op.key}`" v-if="operationInfo[op.key][dir.key][operationName].note">
                          <span class="secondary mr-1">{{$t('integrator.pages.service_view.releases.operation.description')}}</span>
                          <b-icon icon="chat-left-text" scale="1" variant="secondary"/>
                        </b-button>
                      </b-col>
                    </b-row>  
                  </div>
                  
                </template>
                <span v-else class="ml-2"><b-badge variant="light-secondary">{{$t('integrator.pages.service_view.releases.operation.none')}}</b-badge></span>
                <hr class="fainter-hr mb-0 mx-25">
              </div>
              
              <div class="my-1">
                  
                <h5 class="d-inline">{{$t('integrator.pages.service_view.releases.operation.body')}}</h5>

                <template v-if="operationInfo.body && operationInfo.body[dir.key]">

                  <!-- CASE 1: (none)-->
                  <span v-if="operationInfo.body[dir.key].type==1" class="ml-2">
                    <b-badge variant="light-secondary">{{$t('integrator.pages.service_view.releases.operation.none')}}</b-badge>
                  </span>
                  
                  <!-- CASE 2: (JSON)-->
                  <json-viewer
                    v-if="operationInfo.body[dir.key].type==2"
                    class="rounded mb-1 mt-50 view-body mx-1"
                    :value="tryToParse(operationInfo.body[dir.key].schema)"
                    :expand-depth="10"
                    expanded
                    boxed
                    copyable
                    sort
                    theme="jv-dark"
                  />

                  <!-- CASE 3: (XML)-->
                  <div class="editor-container mb-1 mt-50 mx-1" v-if="operationInfo.body[dir.key].type==3">
                    <monaco-editor
                      v-if="operationInfo.body[dir.key].type==3 && expanded"
                      v-model="operationInfo.body[dir.key].schema"
                      language="xml"
                      disableEditing
                    />
                  </div>

                  <!-- CASE 4: (form data)-->
                  <div v-if="operationInfo.body[dir.key].type==4 || operationInfo.body[dir.key].type==5">
                    <div v-for="(operationName, index) in Object.keys(operationInfo.body[dir.key].fields)" :key="'operation-'+uuid()" class="mt-50">

                      <b-popover
                        triggers="focus hover"
                        variant="purple"
                        offset="110"
                        :target="`comment-button-${operationInfo.body[dir.key].fields[operationName].id}-${dir.key}`"
                        placement="lefttop"
                        custom-class="custom-popover-body-comment"
                        >
                        {{operationInfo.body[dir.key].fields[operationName].note}}
                      </b-popover>
  
                      <b-row class="mx-0 mb-75">
                        <b-col md="4" class="w-25 d-flex">
                          <div class="w-50"><b-badge pill variant="light-purple">{{(getDataTypeInfo(operationInfo.body[dir.key].fields[operationName].data_type).label).toUpperCase()}}</b-badge></div>
                          
                          <div class="w-50 ">
                            <span v-if="operationName">{{operationName}}</span>
                            <span v-else><i class="text-muted">{{$t('integrator.pages.service_view.releases.operation.undefined')}}</i></span>
                          </div>            
                        </b-col>
                        <b-col md="4" class="w-50"><span class="text-secondary">
                          <span v-if="operationInfo.body[dir.key].fields[operationName].example">{{operationInfo.body[dir.key].fields[operationName].example}}</span>
                          <span v-else><i class="text-muted">{{$t('integrator.pages.service_view.releases.operation.undefined')}}</i></span>
                        </span></b-col>
                        <b-col md="4" class="text-right">
                          <b-button class="p-0 px-1" variant="none" @click="$bvModal.show('field-comment-modal')" :id="`comment-button-${operationInfo.body[dir.key].fields[operationName.id]}-${dir.key}`" v-if="operationInfo.body[dir.key].fields[operationName.note]">
                            <span class="secondary mr-1">{{$t('integrator.pages.service_view.releases.operation.description')}}</span>
                            <b-icon icon="chat-left-text" scale="1" variant="secondary"/>
                          </b-button>
                        </b-col>
                      </b-row>  
                    </div>
                  </div>


                  <!-- CASE 6: (raw)-->
                  <div class="editor-container mb-1 mt-50 mx-1" v-if="operationInfo.body[dir.key].type==6">
                    <monaco-editor
                      v-if="operationInfo.body[dir.key].type==6 && expanded"
                      v-model="operationInfo.body[dir.key].schema"
                      language="plain"
                      disableEditing
                    />
                  </div>

                  <!-- <br><br>
                  {{operationInfo.body[dir.key]}} <br><br>
                  {{getBodyTypeInfo(operationInfo.body[dir.key].type).label}} <br><br> -->

                </template>
                <span v-else class="ml-2"><b-badge variant="light-secondary">{{$t('integrator.pages.service_view.releases.operation.none')}}</b-badge></span>

              </div>

            </div>
            
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import {
  BCol,
  BRow, 
  BCard,
  BBadge,
  BIcon,
  BButton,
  BCollapse,
  BModal,
  BPopover,
  BSkeleton,
  BIconstack,
} from 'bootstrap-vue'
import JsonViewer from "vue-json-viewer";

import { v4 as uuidv4 } from "uuid";

import monacoEditor from '@/layouts/components/editor/monaco.vue';
import Methods from  "@/custom/class/Enum/Methods.js";
import BodyTypes from "@/custom/class/Enum/BodyTypes.js";
import DataTypes from "@/custom/class/Enum/DataType.js";

export default {
  components: {
    BCol,
    BRow, 
    BCard,
    BBadge,
    BIcon,
    BButton,
    BCollapse,
    BModal,
    BPopover,
    BSkeleton,
    JsonViewer,
    BIconstack,
    monacoEditor
  },
  props: {
    operationInfo: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      expanded: false,

      opFields: [
      {title: "Headers", key:"headers"},
      {title: "Queries", key:"queries"},
      ],

      opDirections: [
      {title: "Request", key:"sent"},
      {title: "Response", key:"received"},
      ],
      noneBadge: "<hr>"
    }
  },
  mounted () {

  },
  methods: {
    getMethodInfo(methodID) { 
      return new Methods().items.find(el => el.id == methodID)
    },
    getDataTypeInfo(dataTypeId){
      return new DataTypes().items.find(el => el.id == dataTypeId)
    },
    getBodyTypeInfo(bodyID){
      return new BodyTypes().items.find(el => el.id == bodyID)
    },
    uuid(){
      return uuidv4()
    },
    tryToParse(value){
      let r;
      try {
        r = JSON.parse(value);
      } catch (err){
        r = value;
      }
      return r;
    }
  },

}
</script>

<style lang="scss" scoped>

.selectable_text{
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  cursor: text;
}

.editor-container{
  border: 2px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.2);
}

.custom-boxed{
  border-left: 3px solid rgba(115, 103, 240, 0.5);
  padding-left: 10px;
  margin-left: 5px;
  margin-right: 5px;
  padding-right: 10px;
  padding-top: 10px;
  background-color: rgba(36, 44, 71, 0.3);
  background-color: rgba(255, 255, 255, 0.01);
  border-right: 1px solid rgba(255, 255, 255, 0.05);
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 0 10px 10px 0;
}

.fainter-hr{
  border-color: rgba(255, 255, 255,0.1);
}
.view-body{
  background-color: #242c47 !important;
  border: none;
}
</style>

<style lang="scss">
.custom-popover-body-comment{
  .popover-body{
    border-radius: 8px !important;
  }
  background: none !important;
}
</style>