export default class BodyTypes {
    constructor() {
        this.items = [
            {
                id: 1,
                label: "None",
                tag: "none",
                has_fields: false,
                has_schema: false,
            },
            {
                id: 2,
                label: "JSON",
                value: "json",
                tag: "application/json",
                has_fields: false,
                has_schema: true,
            },
            {
                id: 3,
                label: "XML",
                value: "xml",
                tag: "application/xml",
                has_fields: false,
                has_schema: true,
            },
            {
                id: 4,
                label: "Form data",
                tag: "multipart/form-data",
                has_fields: true,
                has_schema: false,
            },
            {
                id: 5,
                label: "Form url encoded",
                tag: "application/x-www-form-urlencoded",
                has_fields: true,
                has_schema: false,
            },
            {
                id: 6,
                label: "Raw",
                value: 'text',
                tag: "text/raw",
                has_fields: false,
                has_schema: true,
            },
        ];
    }
}