export default class Methods {
  constructor() {
      this.items = [
          {
              id: 1,
              label: "String",
          },
          {
              id: 2,
              label: "Int",
          },
          {
              id: 3,
              label: "Float",
          },
          {
              id: 4,
              label: "Boolean",
          },
      ];
  }
}